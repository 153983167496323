@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.item {
  border: 1px solid #000000;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.box {
  flex-grow: 1;
  max-width: 800px;
  margin: auto;
  padding: 15px;
  font-family: sans-serif;
  line-height: 1.5;
}
